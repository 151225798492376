import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'

const Success = () => (
  <Layout>
    <h2>Gràcies</h2>
    <p>El seu missatge ha estat enviat</p>
    <Link to="/">Tornar a l'inici</Link>
  </Layout>
)

export default Success
